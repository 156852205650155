<template>
  <div class="top-bar">
    <div v-if="!menuName"></div>
    <div v-else class="top-bar-menu-td">
      <div class="top-bar-navigation-td">
        <div class="top-bar-icon-menu">
            <i class="fa fa-bars" @click="toggleMenu"></i>
        </div>
        <a href="javascript:;" @click="openDashboard" class="top-bar-button-navigation-home">{{ $t("Home") }}</a>
        <span v-if="!isHome"> / </span>
        <a v-if="!isHome" href="javascript:;" class="top-bar-button-navigation">{{ menuName }}</a>
      </div>
      <div class="top-bar-title-td">
        <h1>{{ menuName }}</h1>
      </div>
    </div>

    <div class="top-bar-user-td">
      <button v-if="loggedIn" type="button" class="top-bar-button-balance" @click="openBalance">
        <p :title="$t('Balance')" >{{ balance }}</p>
        <p v-if="walletKind === 'prosumer' || walletKind === 'consumer'" :title="$t('Locked balance')" class="locked-balance"><i class="fa-solid fa-lock"></i> {{ lockedBalance }}</p>
      </button>

      <button v-if="!loggedIn" type="button" class="top-bar-button" :title="$t('Login')" @click="openLogin">
        <i class="fa-solid fa-right-to-bracket"></i>
      </button>

      <button v-if="loggedIn" type="button" class="top-bar-button-img user" :title="$t('User settings')" @click="openUserSettings">
        <img class="btn-image" src="@/assets/user-60.png" />
      </button>

      <button v-if="loggedIn" type="button" class="top-bar-button-img" :title="$t('Wallet settings')" @click="openWalletSettings">
        <img class="btn-image" src="@/assets/wallet.png" />
      </button>

      <button v-if="loggedIn" type="button" class="top-bar-button-img" :title="$t('Account settings')" @click="openAccountSettings">
        <img class="btn-image" src="@/assets/settings.png" />
      </button>

    </div>
  </div>
</template>

<script lang="ts">
import { ColorThemeName, getTheme, setTheme, getCurrency } from "@/control/app-preferences";
import { AuthController } from "@/control/auth";
import { defineComponent } from "vue";
import { getUniqueStringId } from "@/utils/unique-id";
import { Request } from "@asanrom/request-browser";
import { Timeouts } from "@/utils/timeout";

import { ApiBalance } from "@/api/api-group-balance";

export default defineComponent({
    components: {},
    name: "TopBar",
    emits: ["openModal", "toggle-main-menu"],
    setup: function() {
        return {
            loadRequestId: getUniqueStringId(),
        };
    },
    data: function () {
        return {
            loggedIn: AuthController.isAuthenticated(),
            profileImage: AuthController.ProfileImage,
            theme: getTheme(),
            profileName: AuthController.ProfileName || AuthController.Username || "",
            walletKind: AuthController.Wallet ? AuthController.Wallet.kind : "",

            menuName: null,
            isHome: false,

            balance: "",
            lockedBalance: "",
            currency: getCurrency(),
        };
    },
    methods: {
        openUserSettings: function () {
            this.$emit("openModal", "account-settings");
        },

        openWalletSettings: function () {
            this.$emit("openModal", "wallet-settings-open");
        },

        openBalance: function() {
            this.$emit("openModal", "balance-open");
        },

        openAccountSettings: function() {
          this.$router.push({ name: "account-settings" });
        },

        toggleMenu: function () {
            this.$emit("toggle-main-menu");
        },

        openWallet: function() {
          this.$router.push({ name: "wallet" });
        },

        openDashboard: function() {
          this.$router.push({ name: "home" });
        },

        openAbout: function() {
          this.$router.push({ name: "about" });
        },

        openLogin: function() {
          this.$router.push({ name: "login" });
        },

        selectLanguage: function () {
            this.$emit("openModal", "change-language-modal");
        },

        invertTheme: function () {
            setTheme(this.theme === "dark" ? "light" : "dark");
        },

        login: function () {
            this.$requireLogin();
        },

        onAuthChanged: function () {
            this.loggedIn = AuthController.isAuthenticated();
            this.profileName = AuthController.ProfileName || AuthController.Username || "";
            this.profileImage = AuthController.ProfileImage;
            this.walletKind = AuthController.Wallet ? AuthController.Wallet.kind : "";
            this.refresh();
        },

        onThemeChanged: function (t: ColorThemeName) {
            this.theme = t;
        },

        renderName: function (name: string): string {
            return ((name + "").split(" ")[0] + "").split(",")[0] || "???";
        },

        refresh: function() {
            if(this.loggedIn) {
                this.currency = getCurrency();
                this.loadBalance();
            }
        },

        loadBalance: function() {
            Timeouts.Abort(this.loadRequestId);
            Request.Abort(this.loadRequestId);

            Request.Pending(this.loadRequestId, ApiBalance.GetPaymentBalance({ currency: this.currency }))
                .onSuccess((res) => {
                    this.balance = res.balance;
                    this.lockedBalance = res.lockedBalance;
                })
                .onRequestError((err, handleErr) => {
                    handleErr(err, {
                        unauthorized: () => {
                            this.balance = "";
                            this.lockedBalance = "";
                        },
                        notFound: () => {
                            this.balance = "";
                            this.lockedBalance = "";
                        },
                        temporalError: () => {
                            // Retry
                            Timeouts.Set(this.loadRequestId, 1500, this.loadBalance.bind(this));
                        },
                    });
                })
                .onUnexpectedError((err) => {
                    console.error(err);
                    // Retry
                    Timeouts.Set(this.loadRequestId, 1500, this.loadBalance.bind(this));
                });
        },
    },
    mounted: function () {
        this.$listenOnAppEvent("auth-status-changed", this.onAuthChanged.bind(this));
        this.$listenOnAppEvent("theme-changed", this.onThemeChanged.bind(this));
        this.$listenOnAppEvent("balance-changed", this.refresh.bind(this));
        this.$listenOnAppEvent("currency-changed", this.refresh.bind(this));
    },
    beforeUnmount: function () {},
    watch:{
        $route: function(to) {
        this.isHome = false;
        if(to.name) {
            switch(to.name) {
            case 'home':
                this.isHome = true;
                this.menuName = this.$t("Home");
                break;
            case 'energy':
                this.menuName = this.$t("Energy Market");
                break;
            case 'sales-offers':
                this.menuName = this.$t("My Sales Offers");
                break;
            case 'demand-supplies':
                this.menuName = this.$t("My Demand Supplies");
                break;
            case 'auctions':
                this.menuName = this.$t("Auctions");
                break;
            case 'assignments':
                this.menuName = this.$t("Assignments");
                break;
            case 'user-profile':
                this.menuName = this.$t("Profile");
                break;
            case 'settings':
                this.menuName = this.$t("Settings");
                break;
            case 'admin-users':
                this.menuName = this.$t("Users administration");
                break;
            case 'assets':
                this.menuName = this.$t("Assets");
                break;
            case 'asset-metadata':
                this.menuName = this.$t("Assets");
                break;
            case 'policies':
                this.menuName = this.$t("Policies");
                break;
            case 'contracts':
                this.menuName = this.$t("Contracts");
                break;
            case 'contract':
                this.menuName = this.$t("Contract");
                break;
            case 'contract-assets':
                this.menuName = this.$t("Contract");
                break;
            case 'contract-share-with':
                this.menuName = this.$t("Contract");
                break;
            case 'contract-offers':
                this.menuName = this.$t("Contract");
                break;
            case 'transfers':
                this.menuName = this.$t("Transfers");
                break;
            case 'data-provider':
                this.menuName = this.$t("Data Provider");
                break;
            case 'did-register':
                this.menuName = this.$t("Register Identity");
                break;
            case 'verifiable-credentials':
                this.menuName = this.$t("Verifiable credentials");
                break;
            default: 
                this.menuName = null;
                break;
            }
        }
    }
  } 
});
</script>

<style scoped>
.top-bar-icon-menu{
    cursor: pointer;
    margin-right: 5px;
}
.top-bar-icon-menu .fa-bars{
    font-size: 24px;
}
</style>
