// Routes

"use strict";

import { createRouter, createWebHistory, Router, RouteRecordRaw } from "vue-router";
import { Timeouts } from "./utils/timeout";
import { AppEvents } from "./control/app-events";

// Router
// https://router.vuejs.org/guide/#javascript

// List of forbidden routes:
//
//    - /api/*  - This is reserved for the API
//    - /static/* - This is reserved for static assets
//    - /webhooks*/ - Reserved for webhooks

const routes: (RouteRecordRaw & {
    meta?: {
        /**
         * Set it to true for sticky sidebar
         */
        sidebarSticky?: boolean;
    };
})[] = [
    /* General / Home */

    {
        name: "home",
        path: "/",
        component: () => import("@/components/routes/HomePage.vue"),
        meta: {
            sidebarSticky: true
        },
    },

    {
        name: "data-provider",
        path: "/data-providers",
        component: () => import("@/components/routes/DataProviderPage.vue"),
        meta: {
            sidebarSticky: true,
            title: 'Data providers'
        },
    },

    {
        name: "data-provider-metadata",
        path: "/data-provider/:id/meta-data",
        component: () => import("@/components/routes/DataProviderMetadata.vue"),
        meta: {
            sidebarSticky: true,
            title: 'Data provider metadata'
        },
    },

    {
        name: "energy",
        path: "/energy",
        component: () => import("@/components/routes/EnergyMarketPage.vue"),
        meta: {
            sidebarSticky: true,
            title: 'Energy'
        },
    },

    {
        name: "sales-offers",
        path: "/sale-offers",
        component: () => import("@/components/routes/market/MySalesOffersPage.vue"),
        meta: {
            sidebarSticky: true,
            title: 'Sales offers'
        },
    },

    {
        name: "demand-supplies",
        path: "/demand-supplies",
        component: () => import("@/components/routes/market/MyDemandSuppliesPage.vue"),
        meta: {
            sidebarSticky: true,
            title: 'Demand Supplies'
        },
    },

    {
        name: "energy-demo",
        path: "/energy-demo",
        component: () => import("@/components/routes/EnergyManagementDemoPage.vue"),
        meta: {
            sidebarSticky: true,
            title: 'Enery demo'
        },
    },

    {
        name: "auctions",
        path: "/auctions",
        component: () => import("@/components/routes/AuctionsPage.vue"),
        meta: {
            sidebarSticky: true,
            title: 'Auction Demo'
        },
    },

    {
        name: "assignments",
        path: "/auction/:id/assignments",
        component: () => import("@/components/routes/AssigmentsPage.vue"),
        meta: {
            sidebarSticky: true,
        },
    },

    {
        name: "terms",
        path: "/terms",
        component: () => import("@/components/routes/TermsOfUsePage.vue"),
        meta: {
            sidebarSticky: true,
            title: 'Terms'
        },
    },

    {
        name: "cookies",
        path: "/cookies",
        component: () => import("@/components/routes/CookiePolicyPage.vue"),
        meta: {
            sidebarSticky: true,
            title: 'Cookies'
        },
    },

    {
        name: "privacy",
        path: "/privacy",
        component: () => import("@/components/routes/PrivacyPolicyPage.vue"),
        meta: {
            sidebarSticky: true,
            title: 'Privacy'
        },
    },

    /* Dataspace */
    
    {
        name: "policies",
        path: "/policies",
        component: () => import("@/components/routes/PoliciesPage.vue"),
        meta: {
            sidebarSticky: true,
            title: 'Policies'
        },
    },

    {
        name: "contracts",
        path: "/contracts",
        component: () => import("@/components/routes/contracts/ContractsPage.vue"),
        meta: {
            sidebarSticky: true,
            title: 'Contracts'
        },
    },

    {
        name: "contract",
        path: "/contract/:id",
        component: () => import("@/components/routes/contracts/ContractPage.vue"),
        meta: {
            sidebarSticky: true,
            title: 'Contract'
        },
    },

    {
        name: "contract-share-with",
        path: "/contract/:id/share-with",
        component: () => import("@/components/routes/contracts/ShareWithPage.vue"),
        meta: {
            sidebarSticky: true,
            title: 'Share With'
        },
    },

    {
        name: "contract-offers",
        path: "/contract/:id/offers",
        component: () => import("@/components/routes/contracts/ContractOffersPage.vue"),
        meta: {
            sidebarSticky: true,
            title: 'Contract offers'
        },
    },

    {
        name: "transfers",
        path: "/transfers",
        component: () => import("@/components/routes/TransfersPage.vue"),
        meta: {
            sidebarSticky: true,
            title: 'Transfers'
        },
    },

    {
        name: "verifiable-credentials",
        path: "/verifiable-credentials",
        component: () => import("@/components/routes/VerifiableCredentialsPage.vue"),
        meta: {
            sidebarSticky: true,
            title: 'Verifiable credentials'
        },
    },


    /* Auth */

    {
        name: "login",
        path: "/login",
        component: () => import("@/components/routes/auth/LoginPage.vue"),
    },

    {
        name: "tfa-login",
        path: "/login/tfa",
        component: () => import("@/components/routes/auth/TwoFactorLoginPage.vue"),
    },

    {
        name: "wallet-create",
        path: '/wallet/create',
        component: () => import("@/components/routes/wallet/WalletCreationPage.vue"),
    },

    {
        name: "wallet-verification",
        path: '/wallet/verification',
        component: () => import("@/components/routes/kyc/WalletVerification.vue")
    },

    {
        name: "signup",
        path: "/signup",
        component: () => import("@/components/routes/auth/SignupPage.vue"),
    },
    {
        name: "signup-success",
        path: "/signup/success",
        component: () => import("@/components/routes/auth/SignupSuccessPage.vue"),
    },

    {
        name: "forgot-password",
        path: "/password/forgot",
        component: () => import("@/components/routes/auth/ForgotPasswordPage.vue"),
    },
    {
        name: "reset-password",
        path: "/password/reset/:uid/:token",
        component: () => import("@/components/routes/auth/ResetPasswordPage.vue"),
    },

    {
        name: "verify-email",
        path: "/email/verify/:uid/:token",
        component: () => import("@/components/routes/auth/EmailVerifyPage.vue"),
    },

    {
        name: "tp-login",
        path: "/login/tp/:service",
        component: () => import("@/components/routes/auth/ThirdPartyLogin.vue"),
    },

    {
        name: "tp-signup",
        path: "/signup/tp",
        component: () => import("@/components/routes/auth/ThirdPartySignupPage.vue"),
    },

    /* Profile */

    {
        name: "profile",
        path: "/user/:username",
        component: () => import("@/components/routes/profile/ProfilePage.vue"),
        meta: {
            sidebarSticky: true,
            title: 'Profile'
        },
    },

    /* Account */

    {
        name: "account-settings",
        path: "/account-settings",
        component: () => import("@/components/routes/account/AccountSettingsPage.vue"),
        meta: {
            sidebarSticky: true,
            title: 'Account settings'
        },
    },

    /* Admin */

    {
        name: "admin",
        path: "/admin",
        component: () => import("@/components/routes/admin/AdministrationPage.vue"),
        meta: {
            sidebarSticky: true,
            title: 'Administration Page'
        },
    },

    {
        name: "admin-user",
        path: "/admin/users/:id",
        component: () => import("@/components/routes/admin/UserPage.vue"),
        meta: {
            sidebarSticky: true,
            title: 'Identity'
        },
    },

    {
        name: "did-register",
        path: "/did-register",
        component: () => import("@/components/routes/DidRegister.vue"),
        meta: {
            sidebarSticky: true,
            title: 'Identity register'
        },
    },

    /* Default */

    {
        path: "/:catchAll(.*)",
        component: () => import("@/components/routes/NotFoundPage.vue"),
        meta: {
            sidebarSticky: true,
            title: 'Not Found'
        },
    },
];

export function makeApplicationRouter(): Router {
    const router = createRouter({
        // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
        history: createWebHistory(),
        routes, // short for `routes: routes`
    });

    router.beforeEach(() => {
        Timeouts.Set("router-load-state", 300, () => {
            AppEvents.Emit("router-load-state-change", true);
        });
    });

    router.afterEach(() => {
        Timeouts.Abort("router-load-state");
        AppEvents.Emit("router-load-state-change", false);
    });

    return router;
}
